define("discourse/plugins/partly-discourse-plugin/discourse/components/languages-dialog", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
          @closeModal={{@closeModal}}
          @flash={{this.flash}}
          @title={{this.title}}
  >
      <div style="margin-bottom: 12px">
          <div>
              OpenBase will use the languages set in your browser.
              If you need to adjust these settings, you can
              <a href="https://www.google.com/search?q=How+to+change+language+in+{{browser}}" target="_blank">learn how to
                  change them in your browser</a>.
          </div>
          <div>Your languages are currently set to, in order of highest to lowest priority:</div>
          <ol>
              {{#each this.languages as |language|}}
                  <li>{{language}}</li>
              {{/each}}
          </ol>
      </div>
  </DModal>
  */
  {
    "id": "gxqsSLza",
    "block": "[[[8,[39,0],null,[[\"@closeModal\",\"@flash\",\"@title\"],[[30,1],[30,0,[\"flash\"]],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,5,\"margin-bottom: 12px\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            OpenBase will use the languages set in your browser.\\n            If you need to adjust these settings, you can\\n            \"],[44,[[28,[37,3],null,[[\"browser\"],[[28,[32,0],[\"browser\"],null]]]]],[[[10,3],[15,6,[29,[\"https://www.google.com/search?q=How+to+change+language+in+\",[52,[30,2,[\"browser\"]],[28,[30,2,[\"browser\"]],null,null],[28,[32,1],[[30,0],\"browser\",\"[\\\"The `browser` property path was used in the `discourse/plugins/partly-discourse-plugin/discourse/components/languages-dialog.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.browser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[14,\"target\",\"_blank\"],[12],[1,\"learn how to\\n                change them in your browser\"],[13]],[2]]],[1,\".\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[1,\"Your languages are currently set to, in order of highest to lowest priority:\"],[13],[1,\"\\n        \"],[10,\"ol\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"languages\"]]],null]],null],null,[[[1,\"                \"],[10,\"li\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"maybeHelpers\",\"language\"],false,[\"d-modal\",\"div\",\"let\",\"hash\",\"a\",\"if\",\"ol\",\"each\",\"-track-array\",\"li\"]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/components/languages-dialog.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
  class LanguagesDialogComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "title", [_tracking.tracked], function () {
      return "Languages";
    }))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "languages", [_tracking.tracked], function () {
      return [];
    }))();
    #languages = (() => (dt7948.i(this, "languages"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "browser", [_tracking.tracked], function () {
      return "";
    }))();
    #browser = (() => (dt7948.i(this, "browser"), void 0))();
    constructor() {
      super(...arguments);
      const languageNames = new Intl.DisplayNames(["en"], {
        type: "language"
      });
      this.languages = navigator.languages.map(languageCode => languageNames.of(languageCode));
      this.browser = [["Edge", /edg/i], ["Internet Explorer", /trident/i], ["Firefox", /firefox|fxios/i], ["Opera", /opr\//i], ["UC Browser", /ucbrowser/i], ["Samsung Browser", /samsungbrowser/i], ["Chrome", /chrome|chromium|crios/i], ["Safari", /safari/i], ["browser", /.+/i]].find(_ref => {
        let [, value] = _ref;
        return value.test(window.navigator.userAgent);
      }).shift();
    }
  }
  _exports.default = LanguagesDialogComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LanguagesDialogComponent);
});