define("discourse/plugins/partly-discourse-plugin/discourse/templates/connectors/before-header-panel/links", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="display: flex">
      {{#if this.showStandardsLink}}
          <div style="margin-left: 10px;">
              <a href="/standard" class="btn btn-text btn-default bootstrap-mode">
                  <span class="d-button-label">Data Standard</span>
              </a>
          </div>
      {{/if}}
  </div>
  */
  {
    "id": "wnTecIDP",
    "block": "[[[10,0],[14,5,\"display: flex\"],[12],[1,\"\\n\"],[41,[30,0,[\"showStandardsLink\"]],[[[1,\"        \"],[10,0],[14,5,\"margin-left: 10px;\"],[12],[1,\"\\n            \"],[10,3],[14,6,\"/standard\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Data Standard\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"if\",\"a\",\"span\"]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/templates/connectors/before-header-panel/links.hbs",
    "isStrictMode": false
  });
});