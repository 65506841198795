define("discourse/plugins/partly-discourse-plugin/discourse/components/category-tree", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "discourse/plugins/partly-discourse-plugin/utils", "discourse/plugins/partly-discourse-plugin/discourse/components/languages-dialog", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _object, _utils, _languagesDialog, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="display: flex;  justify-content: space-between; align-items: flex-start">
      <h1 style="display: flex; align-items: center; gap: 8px">
          <CatalogIcon/>
          <span>Category tree</span>
      </h1>
      <DButton {{action "openLanguagesDialog"}} class="btn btn-text btn-default bootstrap-mode"
                                                @translatedLabel="Languages"/>
  </div>
  
  {{#if this.isLoading}}
      {{i18n "loading"}}
  {{else if this.error}}
      <div class="alert alert-error">
          {{d-icon "exclamation-triangle"}}
          <span>{{i18n "generic_error"}}</span>
          <p>{{this.error}}</p>
      </div>
  {{else if this.categories.length}}
      <ul id="myUL">
          {{#each this.categories as |category|}}
              <CategoryTreeItem @category={{category}}/>
          {{/each}}
      </ul>
  {{else}}
      <p>{{i18n "search.no_results"}}</p>
  {{/if}}
  */
  {
    "id": "HF5PNEse",
    "block": "[[[10,0],[14,5,\"display: flex;  justify-content: space-between; align-items: flex-start\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,5,\"display: flex; align-items: center; gap: 8px\"],[12],[1,\"\\n        \"],[8,[39,2],null,null,null],[1,\"\\n        \"],[10,1],[12],[1,\"Category tree\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn btn-text btn-default bootstrap-mode\"],[4,[38,5],[[30,0],\"openLanguagesDialog\"],null]],[[\"@translatedLabel\"],[\"Languages\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[1,[28,[35,7],[\"loading\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"error\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n        \"],[1,[28,[35,8],[\"exclamation-triangle\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,7],[\"generic_error\"],null]],[13],[1,\"\\n        \"],[10,2],[12],[1,[30,0,[\"error\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"categories\",\"length\"]],[[[1,\"    \"],[10,\"ul\"],[14,1,\"myUL\"],[12],[1,\"\\n\"],[42,[28,[37,12],[[28,[37,12],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"            \"],[8,[39,13],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,7],[\"search.no_results\"],null]],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"category\"],false,[\"div\",\"h1\",\"catalog-icon\",\"span\",\"d-button\",\"action\",\"if\",\"i18n\",\"d-icon\",\"p\",\"ul\",\"each\",\"-track-array\",\"category-tree-item\"]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/components/category-tree.hbs",
    "isStrictMode": false
  });
  class CategoryTree extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "categories", [_tracking.tracked], function () {
      return [];
    }))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return true;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked], function () {
      return null;
    }))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    constructor() {
      super(...arguments);
      this.loadCategories();
    }
    openLanguagesDialog() {
      this.modal.show(_languagesDialog.default);
    }
    static #_5 = (() => dt7948.n(this.prototype, "openLanguagesDialog", [_object.action]))();
    async loadCategories() {
      try {
        this.error = null;
        this.isLoading = true;
        const response = await fetch(_utils.graphqlApiUrl, {
          method: "POST",
          headers: _utils.graphqlApiHeaders,
          body: JSON.stringify({
            query: `query SearchCategoriesOpenbase {
                    gapc {
                      search_gapc_categories(limit: 500) {
                        items {
                          id
                          name
                        }
                      }
                    }
                  }`
          })
        });
        const result = await response.json();
        this.categories = result.data?.gapc?.search_gapc_categories?.items?.sort((a, b) => a.name.localeCompare(b.name));
        if (!this.categories) {
          this.error = result?.errors?.map(each => each.message)?.join(" | ") || "Error fetching data.";
        }
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.error = e;
        this.isLoading = false;
      }
    }
  }
  _exports.default = CategoryTree;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CategoryTree);
});